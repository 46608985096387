<template>
  <header>
    <div class="header-wrapper">
      <div id="logo-wrapper"
      >
        <svg
          id="logo"
          width="100%"
          height="100%"
          viewBox="0 0 234 157"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xml:space="preserve"
          xmlns:serif="http://www.serif.com/"
          style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 2;"
        >
          <g>
            <path
                d="M71.905,60.766l0,52.868c0,2.154 0.775,3.986 2.326,5.494c1.551,1.508 3.404,2.262 5.559,2.262l2.456,0l0,1.292l-39.167,0l0,-1.292l2.327,0c2.241,0 4.136,-0.754 5.688,-2.262c1.551,-1.508 2.326,-3.383 2.326,-5.623l0,-72.516"
            />
            <path
                d="M144.794,114.797l2.068,2.069c0.517,0.517 1.314,1.4 2.391,2.65c1.077,1.249 2.047,2.326 2.909,3.231c0.861,0.905 1.96,2.047 3.296,3.425c1.335,1.379 2.585,2.586 3.748,3.62c1.164,1.034 2.542,2.154 4.137,3.361c1.594,1.206 3.102,2.219 4.524,3.037c1.422,0.819 3.037,1.594 4.847,2.327c1.81,0.732 3.555,1.249 5.235,1.551c1.681,0.302 3.533,0.452 5.558,0.452c2.025,0 4.115,-0.258 6.27,-0.775l0.517,1.292c-4.826,2.327 -9.695,3.491 -14.607,3.491c-11.289,0 -22.793,-6.248 -34.513,-18.743l-61.269,-65.019l-18.485,-19.777c-4.222,-4.825 -7.411,-8.208 -9.565,-10.147c-2.154,-1.938 -4.072,-3.554 -5.752,-4.847c-1.681,-1.292 -3.296,-2.37 -4.847,-3.231c-3.62,-2.069 -6.528,-3.275 -8.726,-3.62c-2.197,-0.344 -4.265,-0.517 -6.204,-0.517c-1.939,0 -4.029,0.388 -6.269,1.164l-0.517,-1.293c4.739,-2.758 9.608,-4.136 14.606,-4.136c10.858,0 22.362,6.463 34.513,19.389l57.78,61.399l18.355,19.647Z"
            />
            <path
                d="M134.051,94.424l-0,-52.918c-0.086,-3.188 -1.508,-5.515 -4.266,-6.98c-1.206,-0.689 -2.542,-1.034 -4.007,-1.034l-1.68,0l-0,-1.292l32.832,0c9.565,0 17.536,1.917 23.913,5.752c6.377,3.835 9.566,9.328 9.566,16.481c-0,4.05 -1.013,7.669 -3.038,10.857c-2.025,3.189 -4.632,5.386 -7.82,6.593c9.996,0.517 17.278,4.438 21.845,11.763c2.327,3.705 3.49,8.272 3.49,13.701c-0,5.429 -2.09,10.384 -6.269,14.865c-4.18,4.481 -9.501,7.67 -15.964,9.566c-6.378,1.87 -13.091,2.818 -20.139,2.843c-0.863,-0.807 -1.77,-1.7 -2.72,-2.681c-1.322,-1.364 -2.412,-2.497 -3.269,-3.397c0.178,0.002 0.356,0.003 0.534,0.003c8.015,0 14.284,-2.111 18.808,-6.334c4.524,-4.222 6.786,-9.78 6.786,-16.674c-0,-4.309 -0.797,-7.95 -2.391,-10.923c-1.595,-2.973 -3.684,-5.192 -6.269,-6.657c-4.74,-2.844 -10.126,-4.266 -16.158,-4.266l0,-1.163c2.757,0 5.084,-0.991 6.98,-2.973c3.275,-3.447 4.912,-8.143 4.912,-14.089c0,-5.946 -1.314,-10.341 -3.943,-13.185c-2.628,-2.844 -6.743,-4.352 -12.344,-4.524l0,77.353c-1.002,-1.159 -1.749,-1.985 -2.242,-2.478l-2.068,-2.068l-15.079,-16.141Z"
            />
          </g></svg
      >
      </div>
      <div class="burger-wrapper">
        <select class="lang-dropdown" v-model="$root.$i18n.locale">
          <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang.locale">
            {{ lang.flag }}
          </option>
        </select>
        <div class="burger-icon" @click="toggleBurgerMenu">
          <div class="burger-line burger-line-top" :class="{ top__active: isActive }"></div>
          <div class="burger-line burger-line-bottom" :class="{ bot__active: isActive }"></div>
        </div>
      </div>

    </div>
  </header>
</template>

<script>

export default {
  name: "AppHeader",
  data() {
    return {
      isActive: false,
      langs: [
        {
          locale: 'en',
          flag: '🇺🇸'
        },
        {
          locale: 'de',
          flag: '🇩🇪'
        },
      ]
    }
  },
  methods: {
    toggleBurgerMenu() {
      const self = this
      this.isActive = !this.isActive

      if (this.isActive == false) {
        self.$store.state.burgerNavOpen = false
      } else {
        self.$store.state.burgerNavOpen = true
      }
    }
  }
};
</script>

<style scoped lang="scss">
// logo moving gradient
@keyframes moveGradient {
  0% {
    background-position: 0 50%;
  }
  10% {
    background-position: 0 50%;
  }
  20% {
    background-position: 0 50%;
  }
  35% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  65% {
    background-position: 100% 50%;
  }
  80% {
    background-position: 0 50%;
  }
  90% {
    background-position: 0 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

header {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  justify-content: center;
  z-index: 500;
  height: 100px;
  transition: all 0.4s ease-in-out;

  .header-wrapper {
    width: 100%;
    max-width: 2000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 34px 32px 0 32px;
    @media screen and (max-width: 760px) {
      padding-top: 0;
    }
    // logo styling
    #logo-wrapper {
      --border-width: 2px;
      border-radius: var(--border-width);
      background: rgb(33, 33, 36);
      padding: 4px 12px;
      font-size: 24px;
      position: relative;
      @media screen and (max-width: 750px) {
        padding: 2px 6px;
      }

      &:hover {
        #logo path {
          fill: #ffffff;
        }

        #logo {
          transform: scale(1.05) translateY(3px);
        }
      }

      // color effect
      &::after {
        position: absolute;
        content: "";
        top: calc(-1 * var(--border-width));
        left: calc(-1 * var(--border-width));
        z-index: -1;
        width: calc(100% + var(--border-width) * 2);
        height: calc(100% + var(--border-width) * 2);
        background: linear-gradient(
                45deg,
                hsl(224, 91%, 44%),
                hsl(224, 91%, 44%),
                hsl(224, 91%, 44%),
                hsl(224, 91%, 44%),
                hsl(224, 91%, 44%),
                hsl(224, 91%, 44%),
                hsl(224, 91%, 44%),
                hsl(224, 91%, 44%),
                hsl(224, 91%, 44%),
                hsl(0, 92%, 42%),
                hsl(0, 92%, 42%),
                hsl(0, 92%, 42%),
                hsl(0, 92%, 42%),
                hsl(0, 92%, 42%),
                hsl(0, 92%, 42%),
                hsl(0, 92%, 42%),
                hsl(0, 92%, 42%),
                hsl(0, 92%, 42%)
        );
        background-size: 320% 320%;
        background-position: 0 50%;
        border-radius: calc(2 * var(--border-width));
        animation: moveGradient 16s 2s infinite;
      }

      #logo {
        transform: translateY(3px);
        width: 80px;
        height: auto;
        transition: all 0.2s ease-in-out;
        @media screen and (max-width: 750px) {
          width: 60px;
        }

        path {
          fill: #f2f2f2;
        }
      }
    }

    .burger-wrapper {
      display: flex;
      align-items: center;

      .lang-dropdown {
        margin-right: 24px;
        font-size: 32px;
        background: transparent;
        border: none;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
        cursor: pointer;
        option{
          font-size: 16px;
        }
      }

      // burger icon
      .burger-icon {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 24px;
        width: 48px;
        right: 0;

        .burger-line {
          width: 48px;
          height: 4px;
          background: #f2f2f2;
          border-radius: 4px;
          transition: all 0.2s ease-in-out;
        }

        .burger-line-top {
          transform: translateY(2px);
        }

        .top__active {
          transform: translateY(10px);
        }

        .burger-line-bot {
          transform: translateY(-2px);
        }

        .bot__active {
          transform: translateY(-9px);
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

  }
}
</style>
