<template>
  <div class="burger-navigation" :class="{ active: burgerNavOpen }">
    <div class="info">
      <h2 class="headline__lines"><div></div><div></div>Nils Becker</h2>
      <p>Webdesign & Development</p>
      <div class="location__wrapper">
        <img class="icon" src="@/assets/img/maps.svg" alt="Location"> 
        <div>Frankfurt | DE</div>
      </div>
      <div class="mail__wrapper">
        <img class="icon" src="@/assets/img/mail.svg" alt="Mail">
        <a href="mailto:mail@nilsbecker.dev" target="_blank">mail@nilsbecker.dev</a>
      </div>
    </div>

    <div class="links__wrapper">
      <div @click="openLegal" class="link">{{ $t('legalNotice') }}</div>
      <div @click="openLegal" class="link">{{ $t('dataPrivacy') }}</div>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'BurgerNav',
    methods: {
      openLegal() {
        this.$store.state.legalOpen = true
      }
    },
    computed: {
      burgerNavOpen() {
        return this.$store.state.burgerNavOpen
      }
    }
  }
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "source_code_proregular";
  src: url("../assets/fonts/sourcecodepro-regular-webfont.woff2") format("woff2"),
      url("../assets/fonts/sourcecodepro-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "cinzel_decorativeregular";
  src: url("../assets/fonts/cinzeldecorative-regular-webfont.woff2") format("woff2"),
      url("../assets/fonts/cinzeldecorative-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}


.burger-navigation{
  left: 0;
  right: 0;
  position: absolute;
  height: 100vh;
  top: -100vh;
  background-color: rgb(33, 33, 36);
  z-index: 400;
  transition: all .2s ease-in;
  border-bottom: 3px solid #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  .info{
    display: flex;
    flex-direction: column;
    transform: translateY(-24px);
    @media screen and (max-width: 760px) {
      transform: translateY(-48px);
    }
    .headline__lines{
      transform: translateX(10px);
      font-size: 32px;
    }
    p{
      margin-bottom: 32px;
      transform: translateX(36px);
    }
    .location__wrapper, .mail__wrapper{
      display: flex;
      align-items: center;
      color: #f2f2f2;
      img{
        margin-right: 32px;
      }
      a, div{
        font-size: 20px;
        display: inline-block;
        @media screen and (max-width: 1020px) {
          font-size: 17px;
        }
      }
      a{
        cursor: pointer;
        color: #f2f2f2;
      }
      .icon{
        height: 32px;
        @media screen and (max-width: 1020px) {
          height: 28px;
        }
      }
    }
    .location__wrapper{
      margin-bottom: 12px;
    }
  }
  .links__wrapper{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100px;
    margin: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
    @media screen and (max-width: 760px) {
      bottom: 160px;
    }
    .link{
      cursor: pointer;
      color: #9f9f9f;
      &:hover{
        color: #757474;
      }
      &:first-of-type{
        margin-right: 20px;
      }
    }
  }
}
.active{
  top: 0;
}
</style>