<template>
  <div class="about">
    <div class="about-wrapper">
      <div class="about-txt">
        <h2 class="headline__lines">
          <div></div>
          <div></div>
          {{ $t('about.aboutMe') }}
        </h2>
        <p>
          {{ $t('about.content') }}
        </p>
      </div>
      <div class="photo-wrapper">
        <div class="photo">
          <div class="name">Nils Becker</div>
          <img @contextmenu="prevent" src="@/assets/img/portrait.jpg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";

export default {
  name: "About",
  methods: {
    allowPageChange() {
      this.$store.state.allowPageChange = true;
    },

    fadeInAbout() {
      let self = this;
      let fadeInAbout = gsap.timeline();

      fadeInAbout
        .fromTo(".about-txt", 0.7, { opacity: 0, x: -100 }, { opacity: 1, x: 0 })
        .fromTo(".photo-wrapper", 0.7, { opacity: 0, x: 100 }, { opacity: 1, x: 0 }, "-=.7")
        .set("header", { display: "flex" })
        .to("header", 1, { opacity: 1 }, "-=1")
        .call(self.allowPageChange, this, "+=1.5");
    },
    prevent(e) {
      e.preventDefault(); // prevent Right Click on Image
    },
  },
  mounted() {
    this.$store.state.currentPage = 1;
    this.fadeInAbout();
  },
};
</script>

<style lang="scss" scoped>
.about {
  padding: 100px 100px 0 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 760px) {
    padding-right: 0px;
  }
  .about-wrapper {
    width: 100%;
    max-width: 1000px;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 999px) {
      flex-direction: column-reverse;
      justify-content: center;
      transform: translateY(-50px);
    }
    .about-txt {
      width: 50%;
      padding: 0 50px;
      transform: translateY(-24px);
      @media screen and (max-width: 999px) {
        width: 70%;
        padding: 0;
      }
      @media screen and (max-width: 600px) {
        width: 100%;
        padding: 0 32px;
      }
      h2 {
        margin-bottom: 24px;
        font-family: "cinzel_decorativeregular", "serif";
        text-align: right;
        color: #f2f2f2;
        @media screen and (max-width: 999px) {
          text-align: left;
          margin-bottom: 8px;
        }
      }
      p {
        line-height: 24px;
        text-align: right;
        @media screen and (max-width: 999px) {
          text-align: left;
        }
      }
    }
    .photo-wrapper {
      position: relative;
      width: 50%;
      padding: 0 100px;
      height: 400px;
      @media screen and (max-width: 1400px) {
        height: 340px;
      }
      @media screen and (max-width: 999px) {
        height: 200px;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 0;
        margin-bottom: 40px;
      }
      .photo {
        position: absolute;
        display: block;
        img {
          width: auto;
          height: 400px;
          object-fit: cover;
          box-shadow: 10px 15px 25px 0 rgba(0, 0, 0, 0.2);
          display: block;
          transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
          margin-top: -10px;
          @media screen and (max-width: 1400px) {
            height: 340px;
          }
          @media screen and (max-width: 999px) {
            height: 200px;
          }
        }
        @media screen and (min-width: 999px) {
          &:hover {
            img {
              margin-top: 0;
              box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.1);
            }
            .name {
              margin-top: -10px;
            }
          }
        }
        .name {
          width: 167px;
          font-size: 32px;
          font-weight: 700;
          position: absolute;
          z-index: 1;
          color: #f2f2f2;
          transform: translate(-25%, -65%);
          margin: 0;
          line-height: 1.2;
          text-shadow: 3px 3px 6px black;
          transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
          @media screen and (max-height: 667px) {
            transform: translate(-45%, 10px);
          }
        }
      }
    }
  }
}
</style>
