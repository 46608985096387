<template>
  <div class="home">
    <!-- Animated Lines and Text -->
    <div id="center-headline">
      <div class="headline headline-design">
        <div class="letter">W</div>       
        <div class="letter">E</div>       
        <div class="letter">B</div>       
        <div class="letter">D</div>       
        <div class="letter">E</div>       
        <div class="letter">S</div>       
        <div class="letter">I</div>       
        <div class="letter">G</div>       
        <div class="letter">N</div>       
      </div>

      <div class="wrapper-lines">
        <div class="line-1"></div>
        <div class="line-2"></div>
      </div>

      <!-- Typewriter Animation made by Matt Boldt. Go visit www.mattboldt.com -->
      <div class="headline headline-dev">
        <vue-typed-js v-if="typewriterAnim" :strings="['{development}']" :typeSpeed="70">
          <div class="typed">{{ typed }}<span class="typing"></span></div>
        </vue-typed-js>
      </div>

    </div>

    <!-- Scroll Indicator -->
    <div id="scroll-indicator">
      <span class="mouse">
        <span class="mouse-movement"></span>
      </span>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap"

export default {
  name: 'Home',
  data() {
    return {
      typed: '',
    }
  },
  methods: {
    allowPageChange() {
      this.$store.state.allowPageChange = true;
    },

    startTypewriter() {
      this.$store.state.typewriterAnim = true
      this.$store.state.landingAnimationfinished = true
    },

    // initial Landing Animation
    animationOnPageLoad() {
      let self = this;
      let landingAnimation = gsap.timeline()

      landingAnimation
        .set("header", {display: 'none', opacity: 0})
        .set("aside", {display: 'none', opacity: 0})
        .set(".headline", {opacity: 1})
        
        .from(".letter", 1, {x: '-100vw', stagger: 0.07, ease: "easeOut"})
        .call(self.startTypewriter)
        .to(".line-1, .line-2", 1.3, {width: "200vw", ease: "power3.inOut"}, "+=1.7")
        .set("header", {display: "flex"}, "-=1.3")
        .set("aside", {display: "block"}, "-=1.3")
        .set("#scroll-indicator", {display: "block"}, "-=1.3")
        .to(".line-1", {"box-shadow": "0px 0px 20px 5px rgba(0, 0, 255, 0.5)"}, "-=1.2")
        .to(".line-2", {"box-shadow": "0px 0px 20px 5px rgba(255, 0, 0, 0.5)"}, "-=1.2")
        .to("header", 0.5, {opacity: 1, ease: "power3.inOut"}, "-=1.3")
        .to("aside", 0.5, {opacity: 1, ease: "power3.inOut"}, "-=0.9")
        .call(self.allowPageChange)
        .to("#scroll-indicator", 0.5, {opacity: 1, ease: "power3.inOut"}, "+=1");
    },



    // initial Landing Animation
    fadeInLanding() {
      let self = this;
      let landingAnimation = gsap.timeline()

      this.typed = '{development}'

      landingAnimation
        .set("header", {display: "flex"})
        .set(".typing", {display: 'none'})
        .to(".line-1, .line-2", 1.3, {width: "200vw", ease: "power3.inOut"})
        .to(".line-1", {"box-shadow": "0px 0px 20px 5px rgba(0, 0, 255, 0.5)"}, "-=1.2")
        .to(".line-2", {"box-shadow": "0px 0px 20px 5px rgba(255, 0, 0, 0.5)"}, "-=1.2")
        .to("header", 1, {opacity: 1, ease: "power3.inOut"}, "-=1.3")

        .to(".headline", 1, {opacity: 1}, "-=0.5")
        .call(self.allowPageChange)
    },
  },
  mounted() {
    this.$store.state.currentPage = 0
    if(this.landingAnimationfinished === true){
      this.typed = '{webdevelopment}'
      this.fadeInLanding()
    } else {
      this.animationOnPageLoad()
    }
  },
  computed: {
    typewriterAnim() {
      return this.$store.state.typewriterAnim
    },
    landingAnimationfinished() {
      return this.$store.state.landingAnimationfinished
    }
  }
  
}
</script>

<style scoped lang="scss">
@font-face {
  font-family: "source_code_proregular";
  src: url("../assets/fonts/sourcecodepro-regular-webfont.woff2") format("woff2"),
      url("../assets/fonts/sourcecodepro-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "cinzel_decorativeregular";
  src: url("../assets/fonts/cinzeldecorative-regular-webfont.woff2") format("woff2"),
      url("../assets/fonts/cinzeldecorative-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
#center-headline {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  height: 49px;
  @media screen and (max-width: 760px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    height: 99px;
    top: 45%;
  }
  .headline {
    opacity: 0;
    font-size: 32px;
    font-weight: 400;
    z-index: 1;
    user-select: none;
    color: #f2f2f2;
  }
  .headline-design {
    display: flex;
    width: 200px;
    transform: translate(-7px, -28px);
    font-family: "cinzel_decorativeregular", "serif";
  }
  .headline-dev{
    width: 269px;
    transform: translateY(28px);
    font-family: "source_code_proregular", "sans-serif";
    @media screen and (max-width: 760px){
      .typed-element{
        width: 100%;
        text-align: center;
        transform: translateX(20px);
        font-size: 28px;
      }
    }
  }
  .wrapper-lines {
    width: 84px;
    height: 18px;
    position: relative;
    transform: rotate(-70deg);
    .line-1,
    .line-2 {
      position: absolute;
      width: 0;
      height: 4px;
      border-radius: 4px;
      z-index: 0;
    }
    .line-1 {
      left: -110px;
      top: -14px;
      background-color: rgba(0, 0, 255, 1);
      box-shadow: none;
      @media screen and (max-width: 760px) {
        left: -150%;
      }
    }
    .line-2 {
      right: -110px;
      bottom: -14px;
      background-color: rgba(255, 0, 0, 1);
      box-shadow: none;
      @media screen and (max-width: 760px) {
        right: -150%;
      }
    }
  }
}




#scroll-indicator {
  display: none;
  opacity: 0;
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  @media screen and (max-width: 760px) {
    bottom: 0px;
  }
  .mouse {
    position: relative;
    display: block;
    width: 36px;
    height: 63px;
    margin: 0 auto 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 3px solid #f2f2f27c;
    border-radius: 23px;
    .mouse-movement {
      position: absolute;
      display: block;
      top: 29%;
      left: 50%;
      width: 8px;
      height: 8px;
      margin: -4px 0 0 -4px;
      background: #f2f2f2;
      border-radius: 50%;
      -webkit-animation: scroll-ani 4s linear infinite;
      -moz-animation: scroll-ani 4s linear infinite;
      animation: scroll-ani 4s linear infinite;
    }
  }
}

@keyframes scroll-ani {
  0% {
    opacity: 1;
    top: 29%;
  }
  15% {
    opacity: 1;
    top: 50%;
  }
  50% {
    opacity: 0;
    top: 50%;
  }
  100% {
    opacity: 0;
    top: 29%;
  }
}
</style>
