<template>
  <div class="description">
    <img @click="closeComponent" class="close" src="../assets/img/close.svg" alt="Close" />
    <div class="description__wrapper">
      <h2 class="headline__lines">
        <div></div>
        <div></div>
        {{ descriptions[workDescriptionComponent].headline }}
      </h2>
      <h3>{{ descriptions[workDescriptionComponent].description }}</h3>

      <simplebar data-simplebar class="textarea" ref="messagesContainer">
        <p>{{ descriptions[workDescriptionComponent].text }}</p>
        <a class="link" target="_blank" href="https://teamee.de" v-if="workDescriptionComponent == 2">www.teamee.de</a>
      </simplebar>
    </div>
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";

export default {
  name: "WorkDescription",
  components: {
    simplebar,
  },
  data() {
    return {
      descriptions: [
        {
          headline: "Coming Soon",
          description: this.$t('work.workCollection.soon'),
          text: "",
        },
        {
          headline: "Henker & Partner",
          description: this.$t('work.workCollection.henker'),
          text: this.$t('work.workDescription.henker')
        },
        {
          headline: "teamee",
          description: this.$t('work.workCollection.teamee'),
          text: this.$t('work.workDescription.teamee')
        },
        {
          headline: "Grand Plaza Hotel",
          description: "Responsive JavaScript Website",
          text: this.$t('work.workDescription.jsWebsite')
        },
        {
          headline: "Logo Design",
          description: this.$t('work.workCollection.jlu'),
          text: this.$t('work.workDescription.jlu')
        },
      ],
    };
  },
  methods: {
    closeComponent() {
      this.$store.state.showWorkDescriptionComponent = false;
    },
  },
  computed: {
    workDescriptionComponent() {
      return this.$store.getters.WORK_DESCRIPTION_COMPONENT;
    },
  },
};
</script>

<style lang="scss" scoped>
.description {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3000;
  background-color: rgba(0, 0, 0, 0.897);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 0 24px;
  padding-top: 80px;
  .close {
    position: absolute;
    width: 40px;
    right: 24px;
    top: 24px;
    cursor: pointer;
  }
  .description__wrapper {
    display: flex;
    flex-direction: column;
    width: 720px;
    max-width: 100%;
    @media screen and (max-width: 600px) {
      align-self: flex-start;
    }
    h2 {
      margin-bottom: 22px;
      font-size: 54px;
      @media screen and (max-width: 600px) {
        font-size: 32px;
      }
    }
    h3 {
      margin-bottom: 32px;
      font-size: 28px;
      @media screen and (max-width: 600px) {
        font-size: 20px;
      }
    }
    .textarea {
      height: calc(100vh - 348px);
      padding-right: 14px;
      p {
        font-size: 20px;
        line-height: 2;
        @media screen and (max-width: 600px) {
          font-size: 16px;
        }
      }
      .link {
        color: #ff6e00;
        font-size: 20px;
        margin-top: 20px;
        display: inline-block;
        &:hover {
          text-decoration: underline;
          color: #ff862a;
        }
      }
    }
  }
}

.headline__lines {
  div {
    width: 4px;
    height: 44px;
    @media screen and (max-width: 600px) {
      height: 26px;
      width: 2px;
    }
  }
}
</style>
