import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentPage: 0,
    allowPageChange: false,
    typewriterAnim: false,
    landingAnimationfinished: false,
    burgerNavOpen: false,
    legalOpen: false,
    workCollectionOpen: false,
    legalComponent: "LegalNotice",
    workCollectionComponent: 0,
    workDescriptionComponent: 0,
    showWorkDescriptionComponent: false,
    showCookieBanner: true
  },
  mutations: {
    SET_LEGALCOMPONENT_COMPONENT : (state, payload) => {
      state.legalComponent = payload
    },
    SET_WORK_COLLECTION_COMPONENT : (state, payload) => {
      state.workCollectionComponent = payload
    },
    SET_WORK_DESCRIPTION_COMPONENT : (state, payload) => {
      state.workDescriptionComponent = payload
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    LEGALCOMPONENT : state => {
      return state.legalComponent
    },
    WORK_COLLECTION_COMPONENT : state => {
      return state.workCollectionComponent
    },
    WORK_DESCRIPTION_COMPONENT : state => {
      return state.workDescriptionComponent
    },
    CURRENT_PAGE : state => {
      return state.currentPage
    } 
  }
})
