<template>
  <div class="contact">

    <h2 class="headline__lines"><div></div><div></div>
      {{ $t('contact.contact') }}</h2>

    <div class="form__wrapper">
      <form class="form" @submit.prevent="sendEmail">
        <input class="human-check" type="text" v-model="humanCheck">
        <input class="input__text" :class="{ 'input__danger': $v.name.$error }" name="name" placeholder="Name" type="text" title="Name" v-model.trim="$v.name.$model" />
        <input class="input__text" :class="{ 'input__danger': $v.mail.$error }" name="mail" placeholder="E-Mail" type="mail" title="E-Mail" v-model.trim="$v.mail.$model" />
        <textarea class="input__text" :class="{ 'input__danger': $v.message.$error }" name="message" :placeholder="$t('contact.writeSth')" maxlength="500" v-model.trim="$v.message.$model"></textarea>
        <button class="btn btn-horizontal">
          <svg v-if="emailSent" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 24 24" id="Layer_1" version="1.0" viewBox="0 0 24 24" xml:space="preserve"><polyline class="path" fill="none" points="20,6 9,17 4,12" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/></svg>
          <div class="wrapper" v-else>
            <span class="loader" v-if="isLoading"></span>
            <span v-else>{{ $t('contact.send') }}</span>
          </div>
        </button>
      </form>

      <div class="side-info__wrapper">
        <div class="info__outer-container">
          <div class="info__wrapper">
            <div class="location__wrapper">
              <img class="icon" src="@/assets/img/maps.svg" alt="Location"> 
              <div>Frankfurt | DE</div>
            </div>
            <div class="mail__wrapper">
              <img class="icon" src="@/assets/img/mail.svg" alt="Mail">
              <a href="mailto:mail@nilsbecker.dev" target="_blank">mail@nilsbecker.dev</a>
            </div>
          </div>
        </div>
        <hr>
        <p class="rights-reserved">© 2021 All Rights Reserved.</p>
        <div class="links__wrapper">
          <div class="link" @click="openLegal">{{ $t('legalNotice') }}</div>
          <div class="link" @click="openLegal">{{ $t('dataPrivacy') }}</div>
        </div>
        <hr>
      </div>
    </div>
    
  </div>
</template>

<script>
  import { gsap } from "gsap"
  import { required, email  } from 'vuelidate/lib/validators' 
  import axios from 'axios'

  export default {
    name: 'Contact',
    data() {
      return {
        name: '',
        mail: '',
        message: '',
        humanCheck: '',
        isLoading: false,
        emailSent: false
      }
    },
    validations: {
      name: {
        required
      },
      mail: {
        required,
        email
      },
      message: {
        required
      }
    },
    methods: {
      sendEmail() {
        this.$v.$touch()
        if (!this.$v.$invalid && !this.emailSent && this.humanCheck == '') {
          this.isLoading = true
          axios.post('https://formspree.io/f/xvovlbev', {
            name: this.name,          
            from: this.mail,          
            message: this.message
          })
            .then(() => {
              this.isLoading = false
              this.emailSent = true
            })
            .catch(() => {
              this.isLoading = false
            })
        }
      },
      allowPageChange() {
        this.$store.state.allowPageChange = true;
      },

      fadeInContact() {
        let self = this;
        let fadeInContact = gsap.timeline();

        fadeInContact
          .fromTo(".form", 0.7, { opacity: 0, x: -100 }, { opacity: 1, x: 0 })
          .fromTo(".side-info__wrapper", 0.7, { opacity: 0, x: 100 }, { opacity: 1, x: 0 }, "-=.7")
          .set("header", {display: 'flex'})
          .to("header", 1, { opacity: 1}, "-=1")
          .call(self.allowPageChange, this, "+=1.5");
      },
      openLegal() {
        this.$store.state.legalOpen = true
      }
    },
    mounted() {
      this.$store.state.currentPage = 3
      this.fadeInContact();
    }
  }
</script>

<style lang="scss" scoped>
.contact{
  padding: 100px 100px 0 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 1020px) {
    // padding: 50px 80px 0 32px;
    padding: 100px 80px 0 32px;
    justify-content: center;
  }
  h2{
    margin-bottom: 100px;
    transform: translateY(-50px);
    color: #f2f2f2;
    @media screen and (max-width: 1020px) {
      margin-bottom: 32px;
      transform: translateY(0px);
    }
    @media screen and (max-width: 760px) {
      transform: translateY(-40px);
    }
  }
  .form__wrapper{
    display: flex;
    align-items: center;
    transform: translateY(-50px);
    @media screen and (max-width: 1020px) {
      flex-direction: column;
      transform: translateY(0px);
    }
    @media screen and (max-width: 760px) {
      transform: translateY(-40px);
    }
    @media screen and (max-height: 667px){
      transform: translateY(-60px);
    }
    form{
      opacity: 0;
      width: 400px;
      color: #f2f2f2;
      margin-right: 48px;
      @media screen and (max-width: 1020px) {
        margin: 0 0 24px 0;
        padding: 0;
        width: 100%;
        max-width: 400px;
      }
      .human-check{
        display: none;
      }
      .input__text {
        font-family: "roboto_regular", sans-serif;
        border-radius: 5px;
        border: 1px solid white;
        font-size: 17px;
        line-height: 2.35;
        background-color: transparent;
        padding: 0 16px;
        width: 100%;
        margin-bottom: 16px;
        color: #f2f2f2;
        resize: none;
        &:focus {
          outline: none;
        }
        @media screen and (max-width: 1020px) {
          margin-bottom: 8px;
        }
      }
      .btn{
        outline: none;
        font-family: "roboto_regular", sans-serif;
        font-size: 16px;
        width: 100%;
        display:inline-block; 
        position:relative;
        text-decoration:none;
        color: white;
        background-color: transparent;
        border-style:solid;
        height: 41px;
        text-align: center;
        border-width:0 2px 0 2px; 
        border-left-color:blue;
        border-right-color:red;
        cursor: pointer;
        &::after, &::before {
          content:"";
          position:absolute;
          border-style: solid;
          border-width:2px 0 2px 0;
          top:0;
          bottom:0;
          width:10%; 
        }
        &::before{
          border-color:blue;  
          left:0; 
          transition: border-bottom-width 0.075s ease, width 0.55s ease;
        }
        &::after{
          border-color:red;  
          right:0;
          transition: border-bottom-width 0.075s ease, width 0.55s ease;
        }
        &:hover:before{
          border-bottom-width:0;
          width:90%;
        }
        &:hover:after{
          border-top-width:0;
          width:90%;
        }
      }
    }
    .side-info__wrapper{
      width: 400px;
      @media screen and (max-width: 1020px) {
        padding: 0 56px 0 32px;
        width: 100%;
        max-width: 400px;
        padding: 0;
      }
      .info__outer-container{
        display: flex;
        justify-content: center;
        @media screen and (max-width: 1020px) {
          margin-bottom: 8px;
        }
        .info__wrapper{
          display: flex;
          flex-direction: column;
          .location__wrapper, .mail__wrapper{
            display: flex;
            align-items: center;
            margin-bottom: 32px;
            color: #f2f2f2;
            @media screen and (max-width: 1020px) {
              margin-bottom: 12px;
            }
            img{
              margin-right: 48px;
            }
            a, div{
              font-size: 20px;
              display: inline-block;
              @media screen and (max-width: 1020px) {
                font-size: 17px;
              }
            }
            a{
              cursor: pointer;
              color: #f2f2f2;
            }
            .icon{
              height: 32px;
              @media screen and (max-width: 1020px) {
                height: 28px;
              }
            }
          }
        }
      }
    }
  }
  hr{
    margin-bottom: 12px;
  }
  .rights-reserved{
    text-align: center;
    margin-bottom: 12px;
  }
  .links__wrapper{
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
    .link{
      cursor: pointer;
      color: #9f9f9f;
      &:hover{
        color: #757474;
      }
      &:first-of-type{
        margin-right: 20px;
      }
    }
  }
}

.input__danger{
  border: 1px solid red !important;
  animation-name: shakeError;
  animation-fill-mode: forwards;
  animation-duration: .6s;
  animation-timing-function: ease-in-out;
}

@keyframes shakeError {
  0% {
    transform: translateX(0); }
  15% {
    transform: translateX(0.375rem); }
  30% {
    transform: translateX(-0.375rem); }
  45% {
    transform: translateX(0.375rem); }
  60% {
    transform: translateX(-0.375rem); }
  75% {
    transform: translateX(0.375rem); }
  90% {
    transform: translateX(-0.375rem); }
  100% {
    transform: translateX(0); } 
}

// Loader
.loader {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    animation: loaderAnim 1s linear infinite;
    &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 2px solid;
        border-color: white transparent;
    }
}

@keyframes loaderAnim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

// Checkmark
svg {
  $size: 41px;
  width: $size;
  height: $size;
}

.path {
  stroke-linecap: round;
  stroke-dasharray: 27;
  stroke-dashoffset: -23;
  animation: dash 500ms ease forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

</style>