<template>
  <aside>
    <div class="sidenav">
      <div class="label">{{ label }}</div>
      <div class="line"></div>
      <div class="progress-line"></div>
      <div class="arrow"></div>
    </div>
    <div class="nav-anchor anchor-about" @click="goToAbout"></div>
    <div class="nav-anchor anchor-work" @click="goToWork"></div>
    <div class="nav-anchor anchor-contact" @click="goToContact"></div>

    <div class="nav-anchor-label label-about">{{ $t('sidenav.aboutMe') }}</div>
    <div class="nav-anchor-label label-work">{{ $t('sidenav.references') }}</div>
    <div class="nav-anchor-label label-contact">{{ $t('sidenav.contact') }}</div>
  </aside>
</template>

<script>

import {eventBus} from "../main";

export default {
  name: "Sidenav",
  methods: {
    goToAbout() {
      if (this.currentPage == 2) {
        eventBus.$emit('changePage', 'down');
      } 
    },
    goToWork() {
      if (this.currentPage == 1) {
        eventBus.$emit('changePage', 'up'); 
      } 
      if (this.currentPage == 3) {
        eventBus.$emit('changePage', 'down');
      } 
    },
    goToContact() {
      if (this.currentPage == 2) {
        eventBus.$emit('changePage', 'up');
      } 
    }
  },
  computed: {
    label() {
      let currentPage = this.$store.state.currentPage

      switch (currentPage) {
          case 0:
            return this.$t('sidenav.aboutMe')
            
          case 1:
            return this.$t('sidenav.home')
            
          case 2:
            return this.$t('sidenav.aboutMe')
            
          case 3:
            return this.$t('sidenav.references')
            
          default: 
            return this.$t('sidenav.home')
            
        }
    },
    currentPage() {
      return this.$store.getters.CURRENT_PAGE
    }
  }
};
</script>

<style scoped lang="scss">
aside {
  position: absolute;
  z-index: 100;
  width: 100px;
  top: 120px;
  bottom: 50px;
  right: 0;
  @media screen and (max-width: 760px){
    width: 56px;
    bottom: 20px;
  }
  .sidenav {
    .label {
      user-select: none;
      z-index: 50;
      font-size: 0.75rem;
      font-family: "cinzel_decorativeregular", "serif";
      cursor: pointer;
      position: absolute;
      top: calc(50% - 3.1em);
      right: calc(50% - 0.55em);
      writing-mode: vertical-rl;
      letter-spacing: 0.3em;
    }
    &:hover .line {
      height: calc(50% + 70px);
    }
    .line {
      width: 1px;
      height: 0;
      position: absolute;
      right: 50%;
      pointer-events: none;
      background-color: rgba(223, 199, 164, 0.2);
      height: calc(50% - 70px);
      bottom: 0;
      transition-duration: 1.2s;
      transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    .progress-line {
      width: 1px;
      height: 0;
      position: absolute;
      right: 50%;
      pointer-events: none;
      top: 0;
      background-color: blue;
    }
    .arrow {
      width: 3px;
      height: 3px;
      position: absolute;
      right: calc(50% - 1px);
      border-radius: 50%;
      background-color: rgba(223, 199, 164, 0.2);
      bottom: -1px;
    }
  }
  .nav-anchor {
    display: none;
    opacity: 0;
    position: absolute;
    right: 50%;
    width: 41px;
    height: 41px;
    margin-top: -20px;
    margin-right: -20px;
    &::before {
      position: absolute;
      content: "";
      display: block;
      border: 1px solid #dfc7a4;
      width: 7px;
      height: 7px;
      top: 17px;
      left: 17px;
      transform: rotate(45deg);
    }
  }
  .nav-anchor-label {
    user-select: none;
    font-family: "cinzel_decorativeregular", "serif";
    opacity: 0;
    left: calc(25% - 1.1em);
    font-size: 0.75rem;
    letter-spacing: 0.15em;
    overflow: hidden;
    position: absolute;
    text-align: center;
    writing-mode: vertical-rl;
  }
  .anchor-about {
    top: calc(50% - 100px);
  }
  .anchor-work {
    top: calc(50% - 50px);
  }
  .anchor-contact {
    top: calc(50%);
  }
  .bg-red {
    &::before {
      background-color: red;
    }
  }
  .cursor{
    cursor: pointer;
    &:hover::before {
      background-color: red;
    }
  }
  .label-about {
    top: calc(50% - 140px);
  }
  .label-work {
    top: calc(50% - 94px);
  }
  .label-contact {
    top: calc(50% - 32px);
  }
}
</style>
