<template >
  <div id="app" v-hammer:swipe.vertical="checkSwipeDirection">
    <BurgerNav />
    <AppHeader />
    <Sidenav />
    <main>
      <router-view />
    </main>
    <Legal />
    <WorkCollection />
  </div>
</template>

<script>
import { gsap } from "gsap"
import Sidenav from "./components/Sidenav.vue";
import AppHeader from "./components/AppHeader.vue";
import BurgerNav from '@/components/BurgerNav.vue';
import Legal from '@/components/Legal.vue';
import WorkCollection from '@/components/WorkCollection.vue';

import {eventBus} from "./main";


export default {
  components: {
    Sidenav,
    AppHeader,
    BurgerNav,
    Legal,
    WorkCollection,
  },
  data() {
    return {
      btnClick: false,
      acceptCookies: null
    }
  },
  methods: {
    allowPageChange() {
      this.$store.state.allowPageChange = true;
    },
    checkWheelDirection() {
      if (event.deltaY < 0) {
        return "up";
      } else if (event.deltaY > 0) {
        return "down";
      }
    },

    checkSwipeDirection(e) {
      if (e.type === 'swipedown'){
        this.changePage("down")
      } else {
        this.changePage("up")
      }
    },

    detectBtnClick() {
      this.btnClick = true
      this.changePage()
    },

    changePage(swipe){
      if (this.$store.state.allowPageChange && !this.$store.state.burgerNavOpen && !this.$store.state.legalOpen && !this.$store.state.workCollectionOpen) {
        this.$store.state.allowPageChange = false;

        let self = this;
        let currentPage = this.$store.state.currentPage
        let scrollDirection = this.checkWheelDirection()
        let swipeDirection = swipe

 
        switch (currentPage) {
          case 0:
            if (scrollDirection == "down" || swipeDirection == "up" || this.btnClick){
              self.fadeOutHome()
              self.btnClick = false
            } else if (swipeDirection == "down"){
              self.$store.state.allowPageChange = true;
            }
            break;
          case 1:
            if (scrollDirection == "up" || swipeDirection == "down" || this.btnClick){
              const path = "/"
              self.fadeOutAbout(path)
              self.btnClick = false
            } else {
              const path = "Work"
              self.fadeOutAbout(path)
            }
            break;
          case 2:
            if (scrollDirection == "up" || swipeDirection == "down" || this.btnClick){
              const path = "About"
              self.fadeOutWork(path)
              self.btnClick = false
            } else {
              const path = "Contact"
              self.fadeOutWork(path)
            }
            break;
          case 3:
            if (scrollDirection == "up" || swipeDirection == "down" || this.btnClick){
              self.fadeOutContact()
              self.btnClick = false
            }
            break;
        }
      }
    },

    router(path) {
      this.$router.push(path)
    },

    fadeOutHome() {
      let fadeOutHome = gsap.timeline()
      let animateSidenav = gsap.timeline();

      animateSidenav
        .to(".label", 0.5, { top: 0 })
        .to(".line", 0.5, { height: "100%" }, "-=.6")
        .to(".nav-anchor", { display: "block" })
        .to(".nav-anchor", 0.9, { opacity: "1" }, "-=.5")
        .to(".progress-line", 0.8, {height: "calc(50% - 94px)"}, "-=.2")
        .call(function() {document.querySelector(".anchor-about").classList.add("bg-red")})
        .call(function() {document.querySelector(".anchor-work").classList.add("cursor")})
        .to(".label-about", 0.4, { opacity: 1 })
      
      fadeOutHome
        .to("header", 0.1, {opacity: 0})
        .to("#scroll-indicator, .headline", 0.6, {opacity: 0})
        .set("header, #scroll-indicator", {display: "none"})
        .to(".line-1, .line-2", 0.5, {width: "0"})
        .to(".line-1, .line-2", {opacity: "0"}, "+=.1")
        .call(this.router, ['About'])
        .add(animateSidenav, 1.4);
    },

    fadeOutAbout(path){
      let fadeOutAbout = gsap.timeline();
      let animateSidenav = gsap.timeline();

      if (path === 'Work'){
        animateSidenav
          .to(".progress-line", 0.8, {height: "calc(50% - 48px)"}, "+=2")
          .call(function() {document.querySelector(".anchor-work").classList.add("bg-red")})
          .call(function() {document.querySelector(".anchor-contact").classList.add("cursor")})
          .call(function() {document.querySelector(".anchor-about").classList.add("cursor")})
          .call(function() {document.querySelector(".anchor-work").classList.remove("cursor")})
          .to(".label-work", 0.4, { opacity: 1 })
      } else {
        animateSidenav
          .call(function() {document.querySelector(".anchor-about").classList.remove("bg-red")})
          .call(function() {document.querySelector(".anchor-about").classList.remove("cursor")})
          .call(function() {document.querySelector(".anchor-contact").classList.remove("cursor")})
          .call(function() {document.querySelector(".anchor-work").classList.add("cursor")})
          .to(".progress-line", 1.4, {height: "0", ease: "Power4.easeIn"})
          .to(".nav-anchor", 0.9, { opacity: "0" })
          .to(".nav-anchor", { display: "none" })
          .to(".line", 0.5, { height: "calc(50% - 70px)" }, "-=.6")
          .to(".label", 0.6, {top: "calc(50% - 3.1em)"}, "-=.5")
      }
      
      fadeOutAbout
        .to("header", 0.1, {opacity: 0})
        .set("header", {display: 'none'})
        .to(".about-txt", 0.7, {opacity: 0, x: -100})
        .to(".photo-wrapper", 0.7, {opacity: 0, x: 100}, "-=.7")
        .to(".label-about", 0.4, { opacity: 0 }, "-=1.5")
        .call(this.router, [path])
        .add(animateSidenav, 0);
    },

    fadeOutWork(path){
      let fadeOutWork = gsap.timeline();
      let animateSidenav = gsap.timeline();

      const buttons = {
        prev: document.querySelector(".btn-left"),
        next: document.querySelector(".btn-right"),
      };
      const cardsContainerEl = document.querySelector(".cards-wrapper");
      const cardInfosContainerEl = document.querySelector(".info__wrapper");

      if (path === 'Contact'){
        animateSidenav
          .to(".progress-line", 0.8, {height: "calc(50% - 6px)"}, "+=2")
          .call(function() {document.querySelector(".anchor-contact").classList.add("bg-red")})
          .call(function() {document.querySelector(".anchor-work").classList.add("cursor")})
          .call(function() {document.querySelector(".anchor-about").classList.remove("cursor")})
          .call(function() {document.querySelector(".anchor-contact").classList.remove("cursor")})
          .to(".label-contact", 0.4, { opacity: 1 })
      } else {
        animateSidenav
          .call(function() {document.querySelector(".anchor-work").classList.remove("bg-red")})
          .call(function() {document.querySelector(".anchor-about").classList.remove("cursor")})
          .call(function() {document.querySelector(".anchor-contact").classList.remove("cursor")})
          .call(function() {document.querySelector(".anchor-work").classList.add("cursor")})
          .to(".progress-line", 0.8, {height: "calc(50% - 94px)"}, "+=1")
          .to(".label-about", 0.4, { opacity: 1 })
      }
      
      fadeOutWork
        .to("header", 0.1, {opacity: 0})
        .set("header", {display: 'none'})

        .to(
          [buttons.prev, buttons.next],
          {
            duration: 0.4,
            opacity: 0,
            pointerEvents: "none",
          },
          "-=0.4"
        )
        .to(cardInfosContainerEl.querySelector(".pos_current_info").querySelectorAll(".text"), {
          delay: 0.25,
          duration: 0.4,
          stagger: -0.1,
          opacity: 0,
          translateY: 20,
        })
        .to(cardsContainerEl.children, {
         
          duration: 0.1,
          stagger: {
            from: "left",
            amount: 0.1,
          },
          "--card-translateY-offset": "100vh",
        })
        .to(".work", 0.5, {opacity: 0})
          
        .to(".label-work", 0.4, { opacity: 0 }, "-=1.5")
        .call(this.router, [path], "+=.5")
        .add(animateSidenav, 0);
    },

    fadeOutContact(){
      let fadeOutContact = gsap.timeline();
      let animateSidenav = gsap.timeline();

      animateSidenav
        .to(".progress-line", 0.8, {height: "calc(50% - 48px)"}, "+=2")
        .call(function() {document.querySelector(".anchor-contact").classList.remove("bg-red")})
        .call(function() {document.querySelector(".anchor-contact").classList.add("cursor")})
        .call(function() {document.querySelector(".anchor-about").classList.add("cursor")})
        .call(function() {document.querySelector(".anchor-work").classList.remove("cursor")})
        .to(".label-work", 0.4, { opacity: 1 })
 
      fadeOutContact
        .to("header", 0.1, {opacity: 0})
        .set("header", {display: 'none'})
        .to(".form", 0.7, {opacity: 0, x: -100})
        .to(".side-info__wrapper", 0.7, {opacity: 0, x: 100}, "-=.7")
        .to(".label-contact", 0.4, { opacity: 0 }, "-=1.5")
        .call(this.router, ['Work'])
        .add(animateSidenav, 0);
    },
   
  },
  mounted() {
    document.addEventListener('wheel', this.changePage) // bind Wheel Event to whole Document
    document.querySelector(".label").addEventListener('click', this.detectBtnClick)
   
    if(this.$route.name !== 'Home'){
      this.$router.push('/') // always go to home.vue on page reload
    }
  },
  created() {
    eventBus.$on('changePage', (direction) => {
      this.changePage(direction);
    })
  }
}
</script>

<style lang="scss">
@import '@/assets/css/_fonts.scss';

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}


body, html{
  width: 100%;
  position: fixed;
  overflow: hidden;
}

body{
  background-color: rgb(33, 33, 36);
  font-family: "roboto_regular", sans-serif;
  min-height: 100%;
  color: #9f9f9f;
}

#app{
  height: 100vh;
}

.headline__lines {
  font-size: 28px;
  div {
    width: 2px;
    height: 24px;
    background-color: red;
    display: inline-block;
    border-radius: 2px;
    transform: rotate(20deg);
  }
  div:first-of-type {
    background: blue;
    margin-right: 6px;
  }
  div:last-of-type {
    background: red;
    margin-right: 14px;
  }
}


</style>
