<template>
  <div class="work-collection" :class="{ open: workCollectionOpen }" v-hammer:swipe.down="closeSlide">
    <!-- Head Area -->
    <div class="head-area">
      <div class="close-legal">
        <div class="line"></div>
        <img class="arrow" src="../assets/img/double_arrow.svg" alt="Close" title="Close" @click="closeSlide" />
        <div class="line"></div>
      </div>
    </div>

    <div class="container-main">
      <!-- Image Slider -->
      <div class="content__wrapper">
        <div class="slider__wrapper">
          <button class="btn-left" @click="swapCards('left')"><img src="@/assets/img/navigate_before-24px.svg" alt="Prev" /></button>
          <div class="image__wrapper">
            <img :src="images[workCollectionComponent][position]" alt="Image not found" />
          </div>
          <button class="btn-right" @click="swapCards('right')"><img src="@/assets/img/navigate_next-24px.svg" alt="Next" /></button>
        </div>

        <div class="description__wrapper">
          <h2>{{ descriptions[workCollectionComponent].headline }}</h2>
          <h3>{{ descriptions[workCollectionComponent].description }}</h3>
          <div class="link">
            <span @click="showWorkDescription">{{ $t('work.workCollection.goToDesc') }}</span>
            <span class="link-app" v-if="workCollectionComponent == 2"> | <a target="_blank" href="https://teamee.de">{{ $t('work.workCollection.goToApp') }}</a></span>
          </div>
        </div>
      </div>

      <!-- Navigation -->
      <div class="navigation__wrapper">
        <img
          class="work-item"
          :class="{ active: workCollectionComponent == 0 }"
          @click="showImages(0)"
          src="../assets/img/coming_soon_card.svg"
          alt="Coming Soon"
        />
        <img
          class="work-item"
          :class="{ active: workCollectionComponent == 1 }"
          @click="showImages(1)"
          src="../assets/img/henker_partner_card.jpg"
          alt="Henker und Partner"
        />
        <img class="work-item" :class="{ active: workCollectionComponent == 2 }" @click="showImages(2)" src="../assets/img/teamee_card.svg" alt="teamee" />
        <img
          class="work-item"
          :class="{ active: workCollectionComponent == 3 }"
          @click="showImages(3)"
          src="../assets/img/grand_plaza_hotel_card.jpg"
          alt="Grand Plaza Hotel"
        />
        <img
          class="work-item"
          :class="{ active: workCollectionComponent == 4 }"
          @click="showImages(4)"
          src="../assets/img/logo_design_card.jpg"
          alt="JLU Logo Design"
        />
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <WorkDescription v-if="this.$store.state.showWorkDescriptionComponent"></WorkDescription>
    </transition>
  </div>
</template>

<script>
import WorkDescription from "@/components/WorkDescription.vue";

export default {
  name: "WorkCollection",
  components: {
    WorkDescription,
  },
  data() {
    return {
      position: 0,
      images: [
        [require("../assets/img/coming_soon_bg.svg")],
        [
          require("../assets/img/Henker_Partner_0.jpg"),
          require("../assets/img/Henker_Partner_1.jpg"),
          require("../assets/img/Henker_Partner_2.jpg"),
          require("../assets/img/Henker_Partner_3.jpg"),
          require("../assets/img/Henker_Partner_4.jpg"),
          require("../assets/img/Henker_Partner_5.jpg"),
          require("../assets/img/Henker_Partner_6.jpg"),
        ],
        [
          require("../assets/img/teamee_0.jpg"),
          require("../assets/img/teamee_1.jpg"),
          require("../assets/img/teamee_2.jpg"),
          require("../assets/img/teamee_3.jpg"),
          require("../assets/img/teamee_4.jpg"),
          require("../assets/img/teamee_5.jpg"),
          require("../assets/img/teamee_6.jpg"),
        ],
        [
          require("../assets/img/Grand_Plaza_Hotel_0.jpg"),
          require("../assets/img/Grand_Plaza_Hotel_1.jpg"),
          require("../assets/img/Grand_Plaza_Hotel_2.jpg"),
          require("../assets/img/Grand_Plaza_Hotel_3.jpg"),
          require("../assets/img/Grand_Plaza_Hotel_4.jpg"),
        ],
        [require("../assets/img/Logo_Design_0.jpg"), require("../assets/img/Logo_Design_1.jpg"), require("../assets/img/Logo_Design_2.jpg")],
      ],
      descriptions: [
        {
          headline: "Coming Soon",
          description: this.$t('work.workCollection.soon'),
        },
        {
          headline: "Henker & Partner",
          description: this.$t('work.workCollection.henker'),
        },
        {
          headline: "teamee",
          description: this.$t('work.workCollection.teamee'),
        },
        {
          headline: "Grand Plaza Hotel",
          description: "Responsive JavaScript Website",
        },
        {
          headline: "Logo Design",
          description: this.$t('work.workCollection.jlu'),
        },
      ],
    };
  },
  methods: {
    showComponent(name) {
      this.$store.commit("SET_WORK_COLLECTION_COMPONENT", name);
    },
    closeSlide() {
      // set Timeout to prevent scroll down Gesture to previews View
      if (!this.$store.state.showWorkDescriptionComponent) {
        setTimeout(() => {
          this.$store.state.workCollectionOpen = false;
        }, 50);
      }
    },
    showWorkDescription() {
      this.$store.commit("SET_WORK_DESCRIPTION_COMPONENT", this.workCollectionComponent);
      this.$store.state.showWorkDescriptionComponent = true;
    },
    swapCards(direction) {
      let length = this.images[this.workCollectionComponent].length;
      if (direction == "right") {
        if (this.position == length - 1) {
          this.position = 0;
        } else {
          this.position++;
        }
      }
      if (direction == "left") {
        if (this.position == 0) {
          this.position = length - 1;
        } else {
          this.position--;
        }
      }
    },
    showImages(position) {
      this.$store.commit("SET_WORK_COLLECTION_COMPONENT", position);
      this.position = 0;
    },
  },
  computed: {
    workCollectionOpen() {
      return this.$store.state.workCollectionOpen;
    },
    workCollectionComponent() {
      return this.$store.getters.WORK_COLLECTION_COMPONENT;
    },
  },
};
</script>

<style lang="scss" scoped>
.work-collection {
  min-height: 100vh;
  position: absolute;
  bottom: -100%;
  left: 0;
  right: 0;
  transition: all 0.2s ease-in;
  z-index: 2000;
  background-color: rgb(33, 33, 36);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  @media screen and (max-width: 600px) {
    padding-top: 80px;
  }
  .container-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: calc(100vh - 100px);
    @media screen and (max-width: 600px) {
      flex-direction: column-reverse;
      justify-content: flex-end;
      height: calc(100vh - 80px);
    }
    .content__wrapper {
      height: calc(100vh - 280px);
      display: flex;
      flex-direction: column;
      .slider__wrapper {
        flex: 1;
        max-height: calc(100vh - 280px);
        padding: 0 24px;
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 20px;
        @media screen and (max-width: 600px) {
          padding: 0 8px;
          margin-top: 12px;
          flex: 0.7;
        }
        .image__wrapper {
          flex: 1;
          margin: 0 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
          @media screen and (max-width: 600px) {
            margin: 0 8px;
          }
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        button {
          border: none;
          width: 40px;
          height: 40px;
          cursor: pointer;
          pointer-events: auto;
          border-radius: 5px;
          outline: none;
          img {
            width: 40px;
            height: 40px;
          }
          &:active {
            background-color: rgba(102, 100, 100, 0.644);
          }
        }
        .btn-left {
          background-color: white;
          img {
            transform: translateX(-1px);
          }
        }
        .btn-right {
          background-color: white;
        }
      }
      .description__wrapper {
        padding: 0 24px;
        display: flex;
        flex-direction: column;
        h2 {
          text-align: center;
          margin-bottom: 8px;
          color: white;
        }
        h3 {
          text-align: center;
          margin-bottom: 8px;
          @media screen and (max-width: 600px) {
            font-size: 16px;
          }
        }
        .link {
          text-align: center;
          span:hover {
            cursor: pointer;
            color: white;
          }
          .link-app {
            a {
              color: #ff6e00;
              &:hover {
                text-decoration: underline;
                color: #ff862a;
              }
            }
            &:hover {
              color: unset;
            }
          }
        }
      }
    }

    .navigation__wrapper {
      transform: translateY(70%);
      background-color: rgba(0, 0, 0, 0.329);
      height: 220px;
      padding: 0 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease-in-out;
      &:hover {
        transform: translateY(0);
      }
      @media screen and (max-width: 960px) {
        height: 180px;
      }
      @media screen and (max-width: 600px) {
        transform: translateY(0);
        height: 80px;
      }
      .work-item {
        width: 120px;
        height: 180px;
        margin-right: 24px;
        cursor: pointer;
        @media screen and (max-width: 960px) {
          width: 80px;
          height: 120px;
        }
        @media screen and (max-width: 600px) {
          width: 44px;
          height: 60px;
          margin-right: 12px;
        }
        &:last-of-type {
          margin-right: 0;
        }
        &:hover {
          border: 2px solid red;
        }
      }
    }
  }
}

.open {
  bottom: 0;
}

.active {
  border: 2px solid red;
}

.head-area {
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  .close-legal {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .line {
      height: 2px !important;
      background-color: #f2f2f2;
      width: 46vw;
    }
    .arrow {
      width: 48px;
      height: 48px;
      cursor: pointer;
    }
  }
}

// Transitions
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
