<template>
  <div class="legal" :class="{ open: legalOpen }" v-hammer:swipe.down="closeSlide">
    <!-- Head Area -->
    <div class="head-area">
      <div class="close-legal">
        <div class="line"></div>
        <img class="arrow" src="../assets/img/double_arrow.svg" alt="Close" title="Close" @click="closeSlide">
        <div class="line"></div>
      </div>
    </div>

    <!-- Tab Navigation -->
    <div class="tab-navigation">
      <div class="legalnotice" @click="showComponent('LegalNotice')" :class="{active: component === 'LegalNotice'}">
        {{ $t('legalNotice') }}
      </div>
      <div class="dataprivacy" @click="showComponent('DataPrivacy')" :class="{active: component === 'DataPrivacy'}">
        {{ $t('dataPrivacy') }}
      </div>
    </div>

    <component :is="component" />

  </div>
</template>

<script>
  import LegalNotice from '@/components/LegalNotice.vue'
  import DataPrivacy from '@/components/DataPrivacy.vue'
  export default {
    name: 'Legal',
    components: {
      LegalNotice,
      DataPrivacy
    },
    methods: {
      showComponent(name) {
        this.$store.commit("SET_LEGALCOMPONENT_COMPONENT", name);
      },
      closeSlide() {
        // set Timeout to prevent scroll down Gesture to previews View
        setTimeout(() => {
          this.$store.state.legalOpen = false
        }, 50);
      }
    },
    computed: {
      component() {
        return this.$store.getters.LEGALCOMPONENT;
      },
      legalOpen() {
        return this.$store.state.legalOpen
      }
    }
  }
</script>

<style lang="scss" scoped>
.legal{
  height: 100vh;
  position: absolute;
  bottom: -100%;
  left: 0;
  right: 0;
  background-color: rebeccapurple;
  transition: all .2s ease-in;
  z-index: 2000;
  background-color: rgb(33, 33, 36);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 180px;
  @media screen and (max-width: 960px){
    padding: 88px 32px 0;
  }
  .tab-navigation{
    width: 800px;
    display: flex;
    margin-bottom: 24px;
    color: #f2f2f2;
    @media screen and (max-width: 960px){
      width: 100%;
      max-width: 800px;
    }
    .legalnotice, .dataprivacy{
      width: 50%;
      height: 40px;
      border: 1px solid #f2f2f2;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
    }
    .legalnotice{
      border-radius: 5px 0 0 5px;
    }
    .dataprivacy{
      border-radius: 0 5px 5px 0;
    }
  }
}

.open{
  bottom: 0;
}

.active{
  background-color: #f2f2f23d;
}



.head-area {
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  .close-legal {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .line{
      height: 2px !important;
      background-color: #f2f2f2;
      width: 46vw;
    }
    .arrow{
      width: 48px;
      height: 48px;
      cursor: pointer;
    }
  }
}
</style>