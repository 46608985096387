<template>
  <div class="work">
    <div class="carousel-wrapper">
      <div class="carousel">
        <button class="btn-left" @click="swapCards('left')"><img src="@/assets/img/navigate_before-24px.svg" alt="Prev" /></button>
        <div class="cards-wrapper">
          <div class="card pos_0" @click="openWorkCollection($event, 0)">
            <div class="card__image">
              <img src="../assets/img/coming_soon_card.svg" alt="Coming Soon" />
            </div>
          </div>

          <div class="card pos_1" @click="openWorkCollection($event, 1)">
            <div class="card__image">
              <img src="../assets/img/henker_partner_card.jpg" alt="Henker und Partner" />
            </div>
          </div>

          <div class="card pos_current" @click="openWorkCollection($event, 2)">
            <div class="card__image">
              <img src="../assets/img/teamee_card.svg" alt="teamee" />
            </div>
          </div>

          <div class="card pos_2" @click="openWorkCollection($event, 3)">
            <div class="card__image">
              <img src="../assets/img/grand_plaza_hotel_card.jpg" alt="Grand Plaza Hotel" />
            </div>
          </div>

          <div class="card pos_3" @click="openWorkCollection($event, 4)">
            <div class="card__image">
              <img src="../assets/img/logo_design_card.jpg" alt="JLU Logo Design" />
            </div>
          </div>
        </div>
        <button class="btn-right" @click="swapCards('right')"><img src="@/assets/img/navigate_next-24px.svg" alt="Next" /></button>
      </div>

      <div class="infoList">
        <div class="info__wrapper">
          <div class="info pos_0_info">
            <h2 class="text name">?</h2>
            <p class="text description">Coming Soon</p>
          </div>

          <div class="info pos_1_info">
            <h2 class="text name">Henker & Partner</h2>
            <p class="text description">
              Content Management System <br />
              {{ $t('work.henker.desc') }}
            </p>
          </div>

          <div class="info pos_current_info">
            <h2 class="text name">teamee</h2>
            <p class="text description">{{ $t('work.teamee.desc') }}</p>
            <p class="text link" href="">www.teamee.de</p>
          </div>

          <div class="info pos_2_info">
            <h2 class="text name">Grand Plaza Hotel</h2>
            <p class="text description">Responsive JavaScript Website</p>
          </div>

          <div class="info pos_3_info">
            <h2 class="text name">Logo Design</h2>
            <p class="text description">
              {{ $t('work.jlu.desc1') }}<br />
              {{ $t('work.jlu.desc2') }}
            </p>
          </div>
        </div>
      </div>

      <div class="app__bg">
        <div class="app__bg__image pos_0_image">
          <img src="../assets/img/coming_soon_bg.svg" alt="Coming Soon" />
        </div>
        <div class="app__bg__image pos_1_image">
          <img src="../assets/img/henker_partner_bg.jpg" alt="Henker und Partner" />
        </div>
        <div class="app__bg__image pos_current_image">
          <img src="../assets/img/teamee_bg.jpg" alt="teamee" />
        </div>
        <div class="app__bg__image pos_2_image">
          <img src="../assets/img/grand_plaza_hotel_bg.jpg" alt="Grand Plaza Hotel" />
        </div>
        <div class="app__bg__image pos_3_image">
          <img src="../assets/img/logo_design_bg.jpg" alt="JLU Logo Design" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";

export default {
  name: "Work",
  methods: {
    allowPageChange() {
      this.$store.state.allowPageChange = true;
    },
    openWorkCollection(e, position) {
      let classList = e.target.classList;
      // check if clicked Element is current Image
      if (classList.contains("pos_current")) {
        this.$store.state.workCollectionOpen = true;
        this.$store.commit("SET_WORK_COLLECTION_COMPONENT", position);
      }
    },
    fadeInHeader() {
      let fadeInHeader = gsap.timeline();

      fadeInHeader.set("header", { display: "flex" }).to("header", 0.1, { opacity: 1 });
    },

    // initialize cards and buttons
    init() {
      const buttons = {
        prev: document.querySelector(".btn-left"),
        next: document.querySelector(".btn-right"),
      };
      const cardsContainerEl = document.querySelector(".cards-wrapper");
      const cardInfosContainerEl = document.querySelector(".info__wrapper");

      let tl = gsap.timeline();

      tl.to(cardsContainerEl.children, {
        duration: 0.5,
        stagger: {
          ease: "power4.inOut",
          from: "right",
          amount: 0.1,
        },
        "--card-translateY-offset": "0%",
      })
        .to(cardInfosContainerEl.querySelector(".pos_current_info").querySelectorAll(".text"), {
          delay: 0.5,
          duration: 0.4,
          stagger: 0.1,
          opacity: 1,
          translateY: 0,
        })
        .to(
          [buttons.prev, buttons.next],
          {
            duration: 0.4,
            opacity: 1,
            pointerEvents: "all",
          },
          "-=0.4"
        )
        .call(this.allowPageChange);
    },

    swapCards(direction) {
      const cardsContainerEl = document.querySelector(".cards-wrapper");
      const appBgContainerEl = document.querySelector(".app__bg");
      const cardInfosContainerEl = document.querySelector(".info__wrapper");
      const buttons = {
        prev: document.querySelector(".btn-left"),
        next: document.querySelector(".btn-right"),
      };

      // card elements
      const pos_current_Card = cardsContainerEl.querySelector(".pos_current");
      const pos_0_Card = cardsContainerEl.querySelector(".pos_0");
      const pos_1_Card = cardsContainerEl.querySelector(".pos_1");
      const pos_2_Card = cardsContainerEl.querySelector(".pos_2");
      const pos_3_Card = cardsContainerEl.querySelector(".pos_3");

      // background images
      const pos_current_image = appBgContainerEl.querySelector(".pos_current_image");
      const pos_0_image = appBgContainerEl.querySelector(".pos_0_image");
      const pos_1_image = appBgContainerEl.querySelector(".pos_1_image");
      const pos_2_image = appBgContainerEl.querySelector(".pos_2_image");
      const pos_3_image = appBgContainerEl.querySelector(".pos_3_image");

      changeInfo(direction); // change Info Text

      swapCardsClass(); // swap cards on click event

      function swapCardsClass() {
        // remove old card classes
        pos_current_Card.classList.remove("pos_current");
        pos_0_Card.classList.remove("pos_0");
        pos_1_Card.classList.remove("pos_1");
        pos_2_Card.classList.remove("pos_2");
        pos_3_Card.classList.remove("pos_3");

        // remove old bg-image classes
        pos_current_image.classList.remove("pos_current_image");
        pos_0_image.classList.remove("pos_0_image");
        pos_1_image.classList.remove("pos_1_image");
        pos_2_image.classList.remove("pos_2_image");
        pos_3_image.classList.remove("pos_3_image");

        pos_current_Card.style.zIndex = "50";
        pos_current_image.style.zIndex = "-2";

        if (direction == "right") {
          pos_0_Card.style.zIndex = "10";
          pos_1_Card.style.zIndex = "20";
          pos_2_Card.style.zIndex = "40";
          pos_3_Card.style.zIndex = "50";

          pos_2_image.style.zIndex = "-1";

          // add new card classes
          pos_current_Card.classList.add("pos_1");
          pos_0_Card.classList.add("pos_3");
          pos_1_Card.classList.add("pos_0");
          pos_2_Card.classList.add("pos_current");
          pos_3_Card.classList.add("pos_2");

          // add new bg-image classes
          pos_current_image.classList.add("pos_1_image");
          pos_0_image.classList.add("pos_3_image");
          pos_1_image.classList.add("pos_0_image");
          pos_2_image.classList.add("pos_current_image");
          pos_3_image.classList.add("pos_2_image");
        } else if (direction == "left") {
          pos_0_Card.style.zIndex = "40";
          pos_1_Card.style.zIndex = "30";
          pos_2_Card.style.zIndex = "20";
          pos_3_Card.style.zIndex = "10";

          pos_1_image.style.zIndex = "-1";

          // add new card classes
          pos_current_Card.classList.add("pos_2");
          pos_0_Card.classList.add("pos_1");
          pos_1_Card.classList.add("pos_current");
          pos_2_Card.classList.add("pos_3");
          pos_3_Card.classList.add("pos_0");

          // add new bg-image classes
          pos_current_image.classList.add("pos_2_image");
          pos_0_image.classList.add("pos_1_image");
          pos_1_image.classList.add("pos_current_image");
          pos_2_image.classList.add("pos_3_image");
          pos_3_image.classList.add("pos_0_image");
        }
      }

      function changeInfo(direction) {
        let pos_current_Infotext = cardInfosContainerEl.querySelector(".pos_current_info");
        let pos_0_Infotext = cardInfosContainerEl.querySelector(".pos_0_info");
        let pos_1_Infotext = cardInfosContainerEl.querySelector(".pos_1_info");
        let pos_2_Infotext = cardInfosContainerEl.querySelector(".pos_2_info");
        let pos_3_Infotext = cardInfosContainerEl.querySelector(".pos_3_info");

        gsap
          .timeline()
          .to([buttons.prev, buttons.next], {
            duration: 0.2,
            opacity: 0.5,
            pointerEvents: "none",
          })
          .to(
            pos_current_Infotext.querySelectorAll(".text"),
            {
              duration: 0.4,
              stagger: 0.1,
              translateY: "-120px",
              opacity: 0,
            },
            "-="
          )
          .call(() => {
            swapInfosClass(direction);
          })
          .fromTo(
            direction === "right" ? pos_2_Infotext.querySelectorAll(".text") : pos_1_Infotext.querySelectorAll(".text"),
            {
              opacity: 0,
              translateY: "40px",
            },
            {
              duration: 0.4,
              stagger: 0.1,
              translateY: "0px",
              opacity: 1,
            }
          )
          .to([buttons.prev, buttons.next], {
            duration: 0.2,
            opacity: 1,
            pointerEvents: "all",
          });

        function swapInfosClass() {
          pos_current_Infotext.classList.remove("pos_current_info");
          pos_0_Infotext.classList.remove("pos_0_info");
          pos_1_Infotext.classList.remove("pos_1_info");
          pos_2_Infotext.classList.remove("pos_2_info");
          pos_3_Infotext.classList.remove("pos_3_info");

          if (direction === "right") {
            pos_current_Infotext.classList.add("pos_1_info");
            pos_0_Infotext.classList.add("pos_3_info");
            pos_1_Infotext.classList.add("pos_0_info");
            pos_2_Infotext.classList.add("pos_current_info");
            pos_3_Infotext.classList.add("pos_2_info");
          } else if (direction === "left") {
            pos_current_Infotext.classList.add("pos_2_info");
            pos_0_Infotext.classList.add("pos_1_info");
            pos_1_Infotext.classList.add("pos_current_info");
            pos_2_Infotext.classList.add("pos_3_info");
            pos_3_Infotext.classList.add("pos_0_info");
          }
        }
      }
    },
  },
  mounted() {
    this.$store.state.currentPage = 2;
    this.init();
    this.fadeInHeader();
  },
};
</script>

<style lang="scss" scoped>
.work {
  height: 100vh;
  width: calc(100% + 64px);
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(33, 33, 36);
  --card-width: 300px;
  --card-height: 450px;
  --card-transition-duration: 800ms;
  --card-transition-easing: ease;
  @media screen and (max-width: 600px) {
    --card-width: 200px;
    --card-height: 350px;
  }
  .carousel-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 760px) {
      transform: translateX(-14px);
    }
    .app__bg {
      position: absolute;
      width: 100%;
      height: 100%;
      filter: blur(8px);
      pointer-events: none;
      user-select: none;
      overflow: hidden;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #000;
        z-index: 1;
        opacity: 0.8;
      }
      .app__bg__image {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) translateX(var(--image-translate-offset, 0));
        width: 180%;
        height: 180%;
        transition: transform 1000ms ease, opacity 1000ms ease;
        overflow: hidden;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .pos_current_image {
        opacity: 1;
        --image-translate-offset: 0;
      }
      .pos_0_image,
      .pos_1_image,
      .pos_2_image,
      .pos_3_image {
        opacity: 0;
      }
      .pos_0_image {
        --image-translate-offset: -25%;
      }
      .pos_1_image {
        --image-translate-offset: -25%;
      }
      .pos_2_image {
        --image-translate-offset: 25%;
      }
      .pos_3_image {
        --image-translate-offset: 25%;
      }
    }
    .carousel {
      width: calc(5 * var(--card-width));
      height: auto;
      z-index: 1;
      button {
        background-color: transparent;
        border: none;
        width: 40px;
        height: 40px;
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 100;
        opacity: 0;
        pointer-events: none;
        border-radius: 5px;
        outline: none;
        img {
          width: 40px;
          height: 40px;
        }
        &:active {
          background-color: rgba(56, 55, 55, 0.644);
        }
      }
      .btn-left {
        left: 15%;
        z-index: 200;
        background-color: rgba(33, 33, 36, 0.7);
        @media screen and (max-width: 600px) {
          left: 99px;
        }
        img {
          transform: translateX(-1px);
        }
      }
      .btn-right {
        right: 15%;
        z-index: 200;
        background-color: rgba(33, 33, 36, 0.7);
        @media screen and (max-width: 600px) {
          right: 99px;
        }
      }
      .cards-wrapper {
        position: absolute;
        left: 50%;
        perspective: 1000px;
        .card {
          outline: 1px solid transparent; // ANTI-ALIASING FIX
          --card-translateY-offset: 100vh;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) translateX(var(--card-translateX-offset)) translateY(var(--card-translateY-offset))
            rotateY(var(--card-rotation-offset)) scale(var(--card-scale-offset));
          display: inline-block;
          width: var(--card-width);
          height: var(--card-height);
          transition: transform var(--card-transition-duration) var(--card-transition-easing);
          user-select: none;
          cursor: default;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: #000;
            z-index: 1;
            transition: opacity var(--card-transition-duration) var(--card-transition-easing);
            opacity: calc(1 - var(--opacity));
          }
          .card__image {
            position: relative;
            width: 100%;
            height: 100%;
            img {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .pos_current {
          cursor: pointer;
          --current-card-rotation-offset: 0;
          --card-translateX-offset: 0;
          --card-rotation-offset: var(--current-card-rotation-offset);
          --card-scale-offset: 1.2;
          --opacity: 0.8;
        }
        .pos_0 {
          --card-translateX-offset: calc(-1 * var(--card-width) * 2.03);
          --card-rotation-offset: 35deg;
          --card-scale-offset: 0.7;
          --opacity: 0.2;
          @media screen and (max-width: 600px) {
            display: none;
          }
        }
        .pos_1 {
          --card-translateX-offset: calc(-1 * var(--card-width) * 1.1);
          --card-rotation-offset: 25deg;
          --card-scale-offset: 0.9;
          --opacity: 0.4;
          @media screen and (max-width: 600px) {
            display: none;
          }
        }
        .pos_2 {
          --card-translateX-offset: calc(var(--card-width) * 1.1);
          --card-rotation-offset: -25deg;
          --card-scale-offset: 0.9;
          --opacity: 0.4;
          @media screen and (max-width: 600px) {
            display: none;
          }
        }
        .pos_3 {
          --card-translateX-offset: calc(var(--card-width) * 2.03);
          --card-rotation-offset: -35deg;
          --card-scale-offset: 0.7;
          --opacity: 0.2;
          @media screen and (max-width: 600px) {
            display: none;
          }
        }
      }
    }
    .infoList {
      position: absolute;
      width: calc(3 * var(--card-width));
      height: var(--card-height);
      pointer-events: none;
      z-index: 1;
      .info__wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        perspective: 1000px;
        transform-style: preserve-3d;
      }
    }

    .info {
      margin-bottom: calc(var(--card-height) / 8);
      margin-left: calc(var(--card-width) / 1.5);
      transform: translateZ(2rem);
      transition: transform var(--card-transition-duration) var(--card-transition-easing);
      .text {
        position: relative;
        font-size: calc(var(--card-width) * var(--text-size-offset, 0.2));
        white-space: nowrap;
        color: #fff;
        width: fit-content;
      }
      .description {
        --text-size-offset: 0.065;
        font-weight: 500;
      }
      .link {
        font-size: 18px;
        color: orange;
        @media screen and (max-width: 600px) {
          font-size: 14px;
        }
      }

      &.pos_current_info {
        opacity: 1;
        display: block;
        @media screen and (max-width: 1700px) {
          transform: translate(65px, -30px);
        }
        @media screen and (max-width: 600px) {
          transform: translate(20px, -30px);
        }
        h2 {
          @media screen and (max-width: 600px) {
            font-size: 30px;
          }
        }
        .text {
          transform: translateY(40px);
          opacity: 0;
        }
      }
      &.pos_0_info,
      &.pos_1_info,
      &.pos_2_info,
      &.pos_3_info {
        opacity: 0;
        display: none;
      }
    }
  }
}
</style>
